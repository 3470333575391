<template>
  <div>
    <home-slider />
    <donate-section />
  </div>
</template>

<script>
import HomeSlider from "@/components/home_components/HomeSlider.vue";
import DonateSection from "@/components/home_components/DonateSection.vue";

export default {
  name: "HomeView",
  components: {
    HomeSlider,
    DonateSection,
  },
};
</script>
