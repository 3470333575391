<template>
  <div>
    <!-- navbar -->
    <app-header />

    <!-- page content -->
    <router-view />

    <!-- footer -->
    <app-footer />
  </div>
</template>

<script>
import AppHeader from "@/components/layouts/AppHeader.vue";
import AppFooter from "@/components/layouts/AppFooter.vue";
export default {
  components: {
    AppHeader,
    AppFooter,
    },
};

</script>

<style lang="scss"></style>
