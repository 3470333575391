<template>
  <div class="min-vh-100">
    <h2 class="mv0 f1-ns f2 pv4 fw3 tc lh-solid-ns page-header">تواصل معنا</h2>
    <div class="center pv5" style="max-width: 60rem">
      <div class="flex flex-column-sm justify-between">
        <!-- right -->
        <div class="flex-grow-1 ph0-ns ph3">
          <h1 class="ma0 mb3 f3 black">ارسل لنا رسالتك</h1>
          <input v-model="name" type="text" placeholder="الاسم" class="b--near-white db pa3 ba bw1 mb3" />
          <input v-model="phone" type="text" placeholder="الهاتف" class="b--near-white db pa3 ba bw1 mb3" />
          <input v-model="email" type="email" placeholder="البريد الإلكتروني" class="b--near-white db pa3 ba bw1 mb3" />
          <textarea v-model="message" type="text" rows="5" placeholder="رسالتك" class="b--near-white db pa3 ba bw1 mb3"> </textarea>
          <div class="pt1">
            <button type="submit" @click="send()" class="db pv3 ph3 bg-brand white f5 fw6 tc bn br2 dim pointer">ارسال</button>
          </div>

          <!-- alert -->
          <div v-if="isSent == true" class="alert alert-success" role="alert">
            <h4 class="alert-heading tc">{{ msgxd }}</h4>
          </div>
          <div v-else-if="isSent == false" class="alert alert-error" role="alert">
            <h4 class="alert-heading tc">{{ msgxd }}</h4>
          </div>
          <!-- / alert -->
        </div>
        <!-- / right -->

        <!-- left -->
        <div class="flex-grow-1 ph3 mt5-ns">
          <h1 class="ma0 mb3 f3 black">طرق التواصل</h1>
          <div class="mb4">
            <a class="h5">العنوان </a>
            <div class="">{{ contact_address }}</div>
          </div>

          <div class="mb4">
            <a class="h5">البريد الالكتروني </a>
            <div class="">{{ contact_email }}</div>
          </div>

          <div class="mb4">
            <a class="h5">عبر التليفون النقال </a>
            <div class="">{{ contact_mobile }}</div>
          </div>

          <div class="mb4">
            <a class="h5">عبر التليفون العادي </a>
            <div class="">{{ contact_phone }}</div>
          </div>

          <div class="mb4">
            <a class="h5">مواعيد العمل</a>
            <div class="">{{ contact_workingTime }}</div>
          </div>
          <div></div>
        </div>
        <!-- / left -->
      </div>
      <!-- googlemaps -->
      <div class="mt4">
        <iframe src="https://snazzymaps.com/embed/379262" width="100%" height="320px" style="border: none"></iframe>
      </div>
      <!--/ googlemaps -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      name: null,
      phone: null,
      email: null,
      message: null,
      isSent: null,
      msgxd: null,
      contact_address: null,
      contact_mobile: null,
      contact_phone: null,
      contact_workingTime: null,
      contact_email: null,
    };
  },

  methods: {
    send() {
      return axios
        .post(`${this.BaseURL}/contactUs`, {
          name: this.name,
          phone: this.phone,
          email: this.email,
          message: this.message,
        })
        .then((res) => {
          this.msgxd = res.data.msg;
          this.isSent = res.data.success == true ? true : false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getContactsInfo() {
      return axios
        .get(`${this.BaseURL}/website/contacts`)
        .then((res) => {
          this.contact_address = res.data.details.address;
          this.contact_mobile = res.data.details.mobile;
          this.contact_phone = res.data.details.phone;
          this.contact_workingTime = res.data.details.working_time;
          this.contact_email = res.data.details.email;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.getContactsInfo();
  },
};
</script>

<style scoped>
.alert-error {
  padding: 1px;
  margin-top: 1rem;
  width: 335px;
  background: #5f1111;
  color: white;
}
.alert-success {
  padding: 1px;
  margin-top: 1rem;
  width: 335px;
  background: #325a3a;
  color: white;
}
/*! CSS Used from: https://getbootstrap.com/docs/5.0/assets/css/docs.css */
.bd-example > .alert + .alert {
  margin-top: 1rem;
}
.mb4 {
  margin-bottom: 1.5rem;
}
.center {
  margin-left: auto;
}
.center {
  margin-right: auto;
}
.b--near-white {
  border-color: #f4f4f4;
}
textarea {
  width: 300px;
}
@media screen and (max-width: 30em) {
  .flex-column-sm {
    flex-direction: column;
  }
  .mt5-ns {
    margin-top: 4rem;
  }
}
</style>
