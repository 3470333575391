<template>
  <div class="ph4-ns ph3 bg-black white f6" id="footer">
    <div class="center" style="max-width: 1200px">
      <div class="pv5 flex-ns justify-between w-100">
        <div>
          <h4 class="mt0 mb2 f5 brand"><span>تواصل معنا</span></h4>
          <p class="mt0 mb2">{{ address }}</p>
          <p class="mt0 mb2">{{ email }}</p>
          <p class="mv0 tr" dir="LTR">{{ mobile }}</p>
        </div>
        <div class="mr5-ns"></div>
        <div class="mt0-ns mt4 lh-copy">
          <h4 class="mt0 mb2 f5 brand"><span>جمعية الأبرار الخيرية</span></h4>
          <p class="mt0 mb2">
            <router-link class="white dim" :to="{name: 'about'}">من نحن</router-link>
          </p>
          <p class="mv0 mb2">
            <router-link class="white dim" :to="{name: 'members'}">أعضاء مجلس الإدارة</router-link>
          </p>
          <p class="mv0">
            <router-link class="white dim" :to="{name: 'contact'}">تواصل معنا</router-link>
          </p>
        </div>
        <div class="mr5-ns"></div>
        <div class="mt0-ns mt4 lh-copy">
          <h4 class="mt0 mb2 f5 brand"><span>أوقات العمل</span></h4>
          <p class="mt0 mb2 white dm">{{ workingTime }}</p>
        </div>

        <div class="mr5-ns"></div>
        <div class="mt0-ns mt4">
          <h4 class="mt0 mb2 f5 brand"><span>حسابات التواصل الأجتماعي</span></h4>
          <div class="mt3">
            <a :href="facebook" target="_blank" rel="noopener noreferrer" class="mh3 dim"><img src="/images/fb.svg" alt="" style="height: 20px" /></a>
            <a :href="instagram" target="_blank" rel="noopener noreferrer" class="mh3 dim"><img src="/images/instagram.svg" alt="" style="height: 20px" /></a>
            <a rel="nofollow noopener noreferrer" target="_blank" :href="'mailto:'+email" title="Email" class="mh3 dim"><img src="/images/mail.svg" alt="" style="height: 20px" /></a>
          </div>
        </div>
      </div>

      <!-- bottom bar -->
      <p class="mv0 pb3 pv3 tc">جمعية الأبرار الخيرية 2022© جميع الحقوق محفوظة | تم التطوير بواسطة <a target="_blank" href="https://websight.com.kw">websight.com.kw</a></p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      facebook: null,
      instagram: null,
      mobile: null,
      workingTime: null,
      email: null,
      address: null,
    };
  },
  methods: {
    getSocialInfo() {
      return axios
        .get(`${this.BaseURL}/website/social`)
        .then((res) => {
          this.facebook = res.data.details.facebook;
          this.instagram = res.data.details.instagram;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getContactsInfo() {
      return axios
        .get(`${this.BaseURL}/website/contacts`)
        .then((res) => {
          this.address = res.data.details.address;
          this.mobile = res.data.details.mobile;
          this.workingTime = res.data.details.working_time;
          this.email = res.data.details.email;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.getSocialInfo();
    this.getContactsInfo();
  },
};
</script>

<style></style>
