<template>
  <div class="min-vh-100">
    <div class="ph4-ns ph3 pv5 bg-custom-light-gray">
      <div class="center" style="max-width: 1120px">
        <div>
          <div class="mb4 pb3 flex items-start justify-center bb" style="border-color: rgb(219, 224, 229)">
            <h1 class="mv0 black f3-ns f4">{{ project.title }}</h1>
          </div>
          <div class="flex-ns justify-between">
            <div class="w-50-ns flex-shrink-0">
              <div class="relative cover bg-center mb0-ns mb4" :style="'padding-top: 55%;  background-image: url(' + project.photo_file + '); '">
                <div class="absolute absolute--fill bg-black-05 ba b--black-05"></div>
              </div>
            </div>
            <div class="mr4"></div>
            <div class="flex-auto flex flex-column">
              <!-- progress bar -->
              <div v-if="project.target_amount && !expired" class="lh-solid lato mb4">
                <h5 class="mt0 mb2 f4">{{ project.progress }}%</h5>
                <div class="relative">
                  <div class="bg-brand o-50 br-pill" style="height: 12px"></div>
                  <div class="absolute h-100 top-0 bg-brand br-pill" :style="`width: ${project.progress}%`"></div>
                </div>
                <div class="mt2 flex items-center justify-between nl3 nr3 f5">
                  <span class="mh3">0 د.ك</span>
                  <span class="flex-auto">
                    <div class="tl brand nowrap" :style="`width: ${project.progress}%`">
                      <span class="dib">{{ project.current_donations }} د.ك</span>
                    </div>
                  </span>
                  <span class="mh3">{{ project.target_amount }} د.ك</span>
                </div>
              </div>
              <div v-if="!expired" class="mb4 flex-auto flex flex-row items-center" style="max-height: 15rem">
                <div class="fl" style="font-size: 18px">
                  <p class="mt0 mb2"><span>ادخل تبرعك:</span></p>
                  <div class="mv3 lh-solid">
                    <div class="na1 quick-donate">
                      <button @click="changeAmount(5)" type="button" class="ma1 pa3-ns pa2 h-auto bg-white ba b--light-gray br2 f5-ns f6 fw6 pointer grow input-reset custom-gray">
                        <span class="lato">5 د.ك</span>
                      </button>
                      <button @click="changeAmount(10)" type="button" class="ma1 pa3-ns pa2 h-auto bg-white ba b--light-gray br2 f5-ns f6 fw6 pointer grow input-reset custom-gray">
                        <span class="lato">10 د.ك</span>
                      </button>
                      <button @click="changeAmount(20)" type="button" class="ma1 pa3-ns pa2 h-auto bg-white ba b--light-gray br2 f5-ns f6 fw6 pointer grow input-reset custom-gray">
                        <span class="lato">20 د.ك</span>
                      </button>
                      <button @click="changeAmount(50)" type="button" class="ma1 pa3-ns pa2 h-auto bg-white ba b--light-gray br2 f5-ns f6 fw6 pointer grow input-reset custom-gray">
                        <span class="lato">50 د.ك</span>
                      </button>
                      <button @click="changeAmount(100)" type="button" class="ma1 pa3-ns pa2 h-auto bg-white ba b--light-gray br2 f5-ns f6 fw6 pointer grow input-reset custom-gray">
                        <span class="lato">100 د.ك</span>
                      </button>
                      <button @click="changeAmount(200)" type="button" class="ma1 pa3-ns pa2 h-auto bg-white ba b--light-gray br2 f5-ns f6 fw6 pointer grow input-reset custom-gray">
                        <span class="lato">200 د.ك</span>
                      </button>
                    </div>
                  </div>
                  <div class="flex items-stretch lh-solid">
                    <div class="relative flex items-center flex-auto lh-solid fw6">
                      <span class="absolute left-0 ph3">د.ك</span>
                      <input type="number" name="amount" v-model="amount" placeholder="مبلغ آخر" class="w-100 flex-auto pr3 pl3 h-100 db color-inherit bg-transparent ba bw1 br2 input-reset" style="border-color: rgb(219, 224, 229)" />
                    </div>
                    <div class="mr3"></div>
                    <button @click="dialogState = amount > 0 ? true : false" class="pv3 ph4 bg-brand white f5 fw6 bn br2 pointer dim">تبرع</button>
                  </div>
                </div>
              </div>
              <div class="nt2 nb2">
                <p class="mv2 lh-copy">{{ project.details }}</p>
              </div>
              <div class="mt4"></div>
              <div class="cf na1">
                <span v-for="category in project.categories" :key="category.index" class="dib ma1 pv1 ph3 bg-white br-pill f6">
                  <div class="flex items-center">
                    {{ category.title }}
                  </div>
                </span>
              </div>
              <div class="mt4">
                <!-- project date -->
                <div class="flex justify-between">
                  <div class="f6" v-if="project.expire_date">
                    تاريخ البداية : <span> {{ project.date }}</span>
                  </div>
                  <div class="f6" v-if="project.expire_date">
                    تاريخ الانتهاء: <span :style="expired ? 'color: red;' : ''"> {{ project.expire_date }}</span>
                  </div>
                </div>

                <hr style="border: 1px green solid" />
              </div>

              <div class="cf na1 flex flex-column">
                <div v-for="additional in project.fields" :key="additional.index" class="dib ma1 pv1 ph3 br-pill f6">
                  <div class="flex items-center">
                    <li>
                      <b>{{ additional.title }}:</b> {{ additional.value }}
                    </li>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <GDialog v-model="dialogState" max-width="650">
    <div class="relative bg-white" style="max-width: 100%">
      <div class="ph4-ns ph3 flex items-center justify-between bb bw1" style="border-color: rgb(244, 246, 248)">
        <h3 class="ma0 pv3 f3">الدفع</h3>
        <button @click="dialogState = false" class="pa0 bg-transparent bn dim f2 fw2 gray lh-solid pointer">×</button>
      </div>
      <div>
        <div class="ph4-ns ph3 pv3 bg-custom-light-gray">
          <div class="mb3 flex items-start">
            <label class="flex-auto gray"><b>المشروع</b></label>
            <div class="w2"></div>
            <div class="w-50 flex-shrink-0 flex-grow-0">{{ project.title }}</div>
          </div>
          <div class="mb3 flex items-start">
            <label class="flex-auto gray"><b>قيمة التبرع</b></label>
            <div class="w2"></div>
            <div class="w-50 flex-shrink-0 flex-grow-0">{{ amount }} د.ك</div>
          </div>

          <div v-if="!this.$store.state.loggedIn">
            <!-- name -->
            <div class="mb3 flex items-start">
              <input v-model="name" class="w-100 db pa3 ba bw1 b--near-white mb3" type="text" placeholder="الاسم (اختياري)" />
            </div>
            <!-- email -->
            <p v-if="emailInvalid" class="mv0 mb2" style="color: red">{{ emailInvalid }}</p>
            <div class="mb3 flex items-start">
              <input v-model="email" class="w-100 db pa3 ba bw1 b--near-white mb3" type="email" placeholder="البريد الالكتروني (اختياري)" />
            </div>
            <!-- phone -->
            <p v-if="phoneEmpty" class="mv0 mb2" style="color: red">{{ phoneEmpty }}</p>
            <div class="mb3 flex items-start">
              <input v-model="phone" class="w-100 db pa3 ba bw1 b--near-white mb3" type="number" placeholder="رقم الهاتف" />
            </div>
          </div>
          <!-- notes -->
          <div class="mb3 flex items-start">
            <textarea v-model="notes" rows="4" class="w-100 db pa3 ba bw1 b--near-white mb3" type="number" placeholder="ملاحظات" />
          </div>
        </div>

        <div class="ph4 pv3">
          <button @click="payment()" class="db w-100 pv3 ph4 bg-brand white f5 fw6 tc bn br2 pointer dim input-reset">دفع</button>
          <div v-if="!this.$store.state.loggedIn">
            <hr class="mt3" />
            <router-link :to="{name: 'login'}" class="db brand f5 fw6 tc bn br2 pointer dim input-reset"> تسجيل حساب </router-link>
          </div>
        </div>

        <div class="mt3 ph4 pv3 bt b--black-05">
          <p class="ma0 tc gray f6"><span>دفع آمن من خلال MyFatoorah</span></p>
        </div>
      </div>
    </div>
  </GDialog>
</template>

<script>
import {GDialog} from "gitart-vue-dialog";
import "gitart-vue-dialog/dist/style.css";

export default {
  name: "Single Project Page",
  props: ["id"],
  components: {
    GDialog,
  },
  data() {
    return {
      project: [],
      amount: "",
      progress: "",
      expired: "",
      dialogState: false,
      // user info
      user_id: null,
      name: null,
      email: null,
      phone: null,
      notes: null,
      phoneEmpty: null,
      emailInvalid: null,
    };
  },
  methods: {
    SuccessPayment() {
      this.$swal({
        icon: "success",
        title: "تم",
        text: "يرزقك الله من فضله",
      });
    },
    ErrorPayment() {
      this.$swal({
        icon: "error",
        title: "خطأ",
        text: "فشلت عملية الدفع ، حاول مرة اخرى",
      });
    },
    changeAmount(val) {
      this.amount = val;
    },
    getProjectDetails() {
      this.axios
        .get(`${this.BaseURL}/singleProject/${this.id}`)
        .then((res) => {
          this.project = res.data.topic[0];
          this.expired = res.data.expired ? true : false;
        })
        .catch((e) => {
          console.log("error cached: " + e);
        });
    },
    payment() {
      // logged in
      if (this.$store.state.loggedIn) {
        this.user_id = this.$store.state.user.id;
        this.name = this.$store.state.user.name;
        this.email = this.$store.state.user.email;
        this.phone = this.$store.state.user.phone;
      } else {
        // Not logged in
        if (this.phone == null || this.phone.length < 0) {
          return (this.phoneEmpty = "هذا الحقل إجباري");
        }
        this.name = this.name == null || this.name.length < 0 ? "فاعل خير" : this.name;
        this.email = this.email == null || this.email.length < 0 ? "guest@abrarkw.org" : this.email;
      }

      this.axios
        .get(`${this.BaseURL}/payment`, {
          params: {
            user_id: this.user_id,
            name: this.name,
            email: this.email,
            phone: this.phone,
            amount: this.amount,
            notes: this.notes,
            project_id: this.project.id,
          },
        })
        .then((res) => {
          // console.log(res.data);
          window.location.href = res.data.Data.InvoiceURL;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // validate entered email
    validateEmail() {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
        this.emailInvalid = null;
      } else {
        this.emailInvalid = "من فضلك تأكد من كتابة البريد الالكتروني بشكل صحيح";
      }
    },
  },
  mounted() {
    this.getProjectDetails();
    let success = this.$route.query.success;
    if (success == "true") {
      this.SuccessPayment();
      this.$store.state.loggedIn ? this.$router.push({name: "user"}) : "";
    } else if (success == "false") {
      this.ErrorPayment();
    }
  },
};
</script>

<style scoped>
.w-90 {
  width: 90%;
}
.pr3 {
  padding-right: 2rem;
}
button.swal2-confirm.swal2-styled {
  background-color: #13960d !important;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
a {
  background-color: transparent;
}
img {
  border-style: none;
}
button,
input {
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
button,
input {
  overflow: visible;
}
button {
  text-transform: none;
}
[type="button"],
button {
  -webkit-appearance: button;
}
[type="button"]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
[type="button"]:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText;
}
a,
div,
h1,
h5,
input[type="text"],
p {
  box-sizing: border-box;
}
img {
  max-width: 100%;
}
.cover {
  background-size: cover !important;
}
.bg-center {
  background-position: 50%;
}
.bg-center {
  background-repeat: no-repeat;
}
.ba {
  border-style: solid;
  border-width: 1px;
}
.bb {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.bn {
  border-style: none;
  border-width: 0;
}
.b--light-gray {
  border-color: #eee;
}
.b--black-05 {
  border-color: rgba(0, 0, 0, 0.05);
}
.br2 {
  border-radius: 0.25rem;
}
.br-pill {
  border-radius: 9999px;
}
.bw1 {
  border-width: 0.125rem;
}
.top-0 {
  top: 0;
}
.right-0 {
  right: 0;
}
.absolute--fill {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.cf:after,
.cf:before {
  content: " ";
  display: table;
}
.cf:after {
  clear: both;
}
.cf {
  *zoom: 1;
}
.db {
  display: block;
}
.dib {
  display: inline-block;
}
.flex {
  display: flex;
}
.flex-auto {
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0;
}
.flex-column {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.flex-wrap {
  flex-wrap: wrap;
}
.items-start {
  align-items: flex-start;
}
.items-center {
  align-items: center;
}
.items-stretch {
  align-items: stretch;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.fl {
  float: left;
}
.fl {
  _display: inline;
}
.fw6 {
  font-weight: 600;
}
.input-reset {
  -webkit-appearance: none;
  -moz-appearance: none;
}
.input-reset::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.h1 {
  height: 1rem;
}
.h2 {
  height: 2rem;
}
.h-100 {
  height: 100%;
}
.h-auto {
  height: auto;
}
.lh-solid {
  line-height: 1;
}
.lh-copy {
  line-height: 1.5;
}
.w1 {
  width: 1rem;
}
.w-100 {
  width: 100%;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.o-50 {
  opacity: 0.5;
}
.black {
  color: #000;
}
.white {
  color: #fff;
}
.color-inherit {
  color: inherit;
}
.bg-black-05 {
  background-color: rgba(0, 0, 0, 0.05);
}
.bg-silver {
  background-color: #999;
}
.bg-white {
  background-color: #fff;
}
.bg-transparent {
  background-color: transparent;
}
.pa2 {
  padding: 0.5rem;
}
.pl3 {
  padding-left: 1rem;
}
.pr5 {
  padding-right: 4rem;
}
.pb3 {
  padding-bottom: 1rem;
}
.pv1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.pv2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.pv3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.ph3 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.ph4 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.ma1 {
  margin: 0.25rem;
}
.mr1 {
  margin-right: 0.25rem;
}
.mr2 {
  margin-right: 0.5rem;
}
.mr3 {
  margin-right: 1rem;
}
.mr4 {
  margin-right: 2rem;
}
.mb2 {
  margin-bottom: 0.5rem;
}
.mb4 {
  margin-bottom: 2rem;
}
.mt0 {
  margin-top: 0;
}
.mt2 {
  margin-top: 0.5rem;
}
.mt4 {
  margin-top: 2rem;
}
.mv0 {
  margin-top: 0;
  margin-bottom: 0;
}
.mv2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.mv3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.mh3 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.na1 {
  margin: -0.25rem;
}
.nl3 {
  margin-left: -1rem;
}
.nr3 {
  margin-right: -1rem;
}
.nb2 {
  margin-bottom: -0.5rem;
}
.nt2 {
  margin-top: -0.5rem;
}
.tr {
  text-align: right;
}
.tl {
  text-align: left;
}
.f4 {
  font-size: 1.25rem;
}
.f5 {
  font-size: 1rem;
}
.f6 {
  font-size: 0.875rem;
}
.nowrap {
  white-space: nowrap;
}
.dim {
  opacity: 1;
}
.dim,
.dim:focus,
.dim:hover {
  transition: opacity 0.15s ease-in;
}
.dim:focus,
.dim:hover {
  opacity: 0.5;
}
.dim:active {
  opacity: 0.8;
  transition: opacity 0.15s ease-out;
}
.grow {
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;
}
.grow:focus,
.grow:hover {
  transform: scale(1.05);
}
.grow:active {
  transform: scale(0.9);
}
.pointer:hover {
  cursor: pointer;
}
@media screen and (min-width: 30em) {
  .flex-ns {
    display: flex;
  }
  .w-50-ns {
    width: 50%;
  }
  .pa3-ns {
    padding: 1rem;
  }
  .mb0-ns {
    margin-bottom: 0;
  }
  .f3-ns {
    font-size: 1.5rem;
  }
  .f5-ns {
    font-size: 1rem;
  }
}
.custom-gray {
  color: #3bac07;
}
button,
input {
  outline: none;
}
.lato {
  font-family: Noto Kufi Arabic, sans-serif;
  direction: rtl;
}
/* xd */
/* @media screen and (max-width: 30rem) { */
.quick-donate {
  display: flex;
  flex-wrap: wrap;
}
.quick-donate button {
  flex: 1 0 23%;
  text-align: center;
}
/* } */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 0;
}
</style>
