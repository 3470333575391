import {createStore} from "vuex";
import createPersistedState from "vuex-persistedstate";
import router from "@/router";
export default createStore({
  state: {
    loggedIn: false,
    user: {},
  },
  getters: {},
  mutations: {
    logIn(state, payload) {
      state.loggedIn = payload.loggedIn;
      state.user = payload.user;
    },

    logOut(state) {
      state.loggedIn = false;
      state.user = "";
      if (window.location.pathname == "/user") {
        router.push("/login");
      }
    },
  },
  actions: {},
  modules: {},

  plugins: [createPersistedState()],
});
