<template>
  <div class="get-overlay"></div>
  <div class="ph4-ns ph3 relative bg-white f6-m z-5 f6">
    <div class="center" style="max-width: 1120px">
      <div class="flex items-center justify-between style-module--header--30MhH">
        <div class="flex items-center">
          <router-link class="flex-shrink-0" to="/"><img src="/images/logo.jpeg" alt="" class="h3 nt2 db" /></router-link>
          <div class="mr3"></div>
          <!-- menu -->
          <ul id="menu" class="list relative-ns absolute bg-white left-0 w-auto-ns w-100 h-auto-ns vh ma0 pa0 pb0-ns pb3 ph0-ns ph2 dn flex-ns flex-column flex-row-ns items-center z-5 mr5-ns style-module--nav---nYhG">
            <!-- <li class="dn-ns">
              <img src="/images/logo.jpeg" alt="">
            </li> -->
            <li class="flex-shrink-0 w-auto-ns w-100 ph2 pt0-ns pt3">
              <router-link @click="humburger" :to="{name: 'about'}" class="db">من نحن</router-link>
            </li>
            <li class="flex-shrink-0 w-auto-ns w-100 ph2 pt0-ns pt3">
              <router-link @click="humburger" :to="{name: 'members'}" class="db">أعضاء الإدارة</router-link>
            </li>
            <li class="flex-shrink-0 w-auto-ns w-100 ph2 pt0-ns pt3">
              <router-link @click="humburger" :to="{name: 'zakah'}" class="db">الزكاه</router-link>
            </li>
            <li class="flex-shrink-0 w-auto-ns w-100 ph2 pt0-ns pt3">
              <router-link @click="humburger" :to="{name: 'projects'}" class="db">المشاريع</router-link>
            </li>
            <li class="flex-shrink-0 w-auto-ns w-100 ph2 pt0-ns pt3">
              <router-link @click="humburger" :to="{name: 'past-projects'}" class="db">المشاريع المكتملة</router-link>
            </li>
            <li class="flex-shrink-0 w-auto-ns w-100 ph2 pt0-ns pt3">
              <router-link @click="humburger" :to="{name: 'contact'}" class="db">تواصل معنا</router-link>
            </li>

            <li v-if="this.$store.state.loggedIn" class="dn-ns flex items-center justify-center flex-shrink-0 w-auto-ns w-100 ph2 pt0-ns pt3">
              <router-link @click="humburger" :to="{name: 'user'}" class="flex items-center dim">
                <img src="/images/user.svg" alt="" />
                <span class="mr2"></span>
                <span class="black">{{ this.$store.state.user.name }}</span>
              </router-link>
              <span class="mr2"></span>
              <button @click="logout()" class="pa1 flex items-center bg-brand white f6 bn br-pill input-reset outline-0 pointer dim">
                <img src="/images/log-out.svg" alt="" class="w1" />
              </button>
            </li>
            <li v-if="!this.$store.state.loggedIn" class="dn-ns flex-shrink-0 w-auto-ns w-100 ph2 pt0-ns pt3">
              <router-link @click="humburger" :to="{name: 'login'}" style="color: black" class="dib pa2 bn-ns ba bw1 b--black br2 pointer dim">
                <span class="flex items-center fw6">
                  <img src="/images/user.svg" alt="" />
                  دخول
                </span>
              </router-link>
            </li>
          </ul>
        </div>
        <div class="flex items-center">
          <router-link :to="{name: 'projects'}" class="flex-shrink-0 fw5 pv2 ph2 ba b--brand br2 dim f5-ns f7 mh3">تبرع الان</router-link>

          <!-- if not logged -->
          <router-link :to="{name: 'login'}" v-if="!this.$store.state.loggedIn" class="mh2 flex-shrink-0 flex-ns dn items-center pv2 pv0-ns ph0-ns ph1 black bn-ns ba b--black br2 dim">
            <img src="/images/user.svg" class="db-ns dn" />
            <span class="mr2 db-ns dn">دخول</span>
          </router-link>

          <!-- if logged -->
          <div v-if="this.$store.state.loggedIn" class="flex-ns dn items-center">
            <router-link :to="{name: 'user'}" class="flex items-center color-inherit dim">
              <img src="/images/user.svg" alt="" />
              <span class=""></span>
              <span class="nowrap truncate mr2">{{ this.$store.state.user.name }}</span>
            </router-link>
            <button @click="logout()" class="pa2 mr3 flex flex-shrink-0 items-center bg-brand white f6 bn br-pill input-reset outline-0 pointer dim">
              <img src="/images/log-out.svg" alt="" class="w1" />
            </button>
          </div>

          <button @click="humburger()" id="burgerButton" type="button" class="dn-ns flex flex-shrink-0 pa0 bn bg-transparent dim pointer">
            <img src="/images/hamburger.svg" class="mh2" height="30" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$store.commit("logOut");
    },

    humburger() {
      document.getElementById("menu").classList.toggle("d-block");
      document.querySelector(".get-overlay").classList.toggle("overlay");
      document.querySelector(".overlay").addEventListener("click", function () {
        document.querySelector(".overlay").classList.toggle("overlay");
        document.getElementById("menu").classList.toggle("d-block");
      });
    },
  },
};
</script>
