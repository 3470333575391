<template>
  <div class="min-vh-100">
    <h2 class="mv0 f1-ns f2 pv4 fw3 tc lh-solid-ns page-header">{{ pageDetails.title }}</h2>
    <div class="ph4-ns ph3 pt3 pb5">
      <!-- page content -->
      <div class="center" style="max-width: 1120px" v-html="pageDetails.details"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageDetails: "",
    };
  },
  methods: {
    getPageDetails() {
      return this.axios
        .get(`${this.BaseURL}/topic/2`)
        .then((response) => {
          this.pageDetails = response.data.topic[0];
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },

  mounted() {
    this.getPageDetails();
  },
};
</script>
