<template>
  <div class="min-vh-100">
    <h2 class="mv0 f1-ns f2 pv4 fw3 tc lh-solid-ns page-header">المشاريع المكتملة</h2>

    <div class="center pv5 ph0-ns ph3">
      <div class="center" style="max-width: 1120px">
        <div class="pb5">
          <div v-if="isEmpty" class="db-ns flex items-baseline justify-between pb5">
            <p class="dib ma0 f3 tc w-100">لا يوجد بيانات</p>
            <span class="dib-ns dn w3"></span>
          </div>

          <div class="relative flex items-center nl4-ns nr4-ns nl2 nr2">
            <div class="flex-auto flex items-start flex-wrap">
              <!-- project item -->
              <div v-for="project in projects" :key="project.id" class="db w-third-ns w-50-m pt3 pb4 ph4 w-33 flex-grow-1-md">
                <router-link :isEmpty="true" :to="{name: 'singleProject', params: {id: project.id}}">
                  <div class="bg-white black hover-brand pointer grow style-module--wrapper--2E4lN" style="direction: rtl">
                    <img class="w-100" height="205" :src="project.photo_file" />
                    <div class="absolute absolute--fill bg-black-05 ba b--black-05"></div>
                    <!-- </div> -->
                    <div class="pa3 tr">
                      <h5 class="mv0 f5 fw6 lh-copy truncate-ns">{{ project.title }}</h5>
                      <p class="h1 mb0 mt2 gray f6 truncate-ns lh-solid">{{ project.categories[0].title }}</p>
                      <div class="mt3 flex items-center justify-between">
                        <!-- <img src="https://media.give.org.kw/media/Charity/logos/%D9%84%D9%88%D8%AC%D9%88_%D8%B1%D8%AD%D9%85%D8%A7%D8%A1_%D8%AC%D8%AF%D9%8A%D8%AF.PNG" class="h2" /> -->
                        <span class="h2"> </span>
                        <div class="mh2"></div>
                        <!-- <div class="flex items-center lato br-pill flex-auto flex-grow-0 style-module--wrapper--3NhEB">
                            <span class="ph3-ns ph2 f6 nowrap truncate black">{{ project.fileds != null ? project.fields[1].value : "" }}</span>
                            <div class="relative flex items-center brand br-pill style-module--progress--38OK7">
                              <span class="w-100 tc absolute center black f7 fw5">50%</span><svg height="48" viewBox="0 0 36 36"><path stroke-dasharray="50, 100" stroke="currentColor" stroke-width="4" stroke-linecap="round" fill="none" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"></path></svg>
                            </div>
                          </div> -->
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Zakah View",

  data() {
    return {
      projects: [],
      isEmpty: false,
      msg: "",
    };
  },

  methods: {
    getProjects: function () {
      return this.axios
        .get(`${this.BaseURL}/pastProjects`)
        .then((res) => {
          this.isEmpty = !res.data.topics ? true : false;
          this.projects = res.data.topics;
          this.msg = res.data.msg;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },

  mounted() {
    this.getProjects();
  },
};
</script>
