import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const app = createApp(App);

// axios config
axios.defaults.headers.common = {
  "X-Requested-With": "XMLHttpRequest",
  "Content-Type": "application/json",
};

// global variables
// app.config.globalProperties.BaseURL = "https://admin.abrarkw.org/api/v1";
// app.config.globalProperties.BaseURL = "http://localhost:8000/api/v1";
app.config.globalProperties.BaseURL = configxd.BaseURL;
app.config.globalProperties.loaderGIF = "/rolling.gif";

app.use(VueAxios, axios);
app.use(store);
app.use(router);
app.use(VueSweetalert2);
app.mount("#app");
