<template>
  <div class="min-vh-100">
    <div v-if="success == true" class="alert alert-success" role="alert">
      <h4 class="alert-heading tc">{{ msgxd }}</h4>
    </div>
    <div v-else-if="success == false" class="alert alert-error" role="alert">
      <h4 class="alert-heading tc">{{ msgxd }}</h4>
    </div>

    <div class="pv5 ph0-ns ph3 center" style="max-width: 25rem">
      <h1 class="ma0 mb4 f3 black">تسجيل الحساب</h1>
      <input name="name" v-model="name" type="text" placeholder="الاسم" class="db w-100 pa3 ba bw1 b--near-white mb3" />
      <input name="phone" v-model="phone" type="text" placeholder="الهاتف" class="db w-100 pa3 ba bw1 b--near-white mb3" />
      <input name="email" v-model="email" type="email" placeholder="عنوان البريد الإلكتروني" class="db w-100 pa3 ba bw1 b--near-white mb3" />
      <input name="password1" v-model="password" type="password" placeholder="كلمة السر" class="db w-100 pa3 ba bw1 b--near-white mb3" />
      <div class="pt1">
        <button type="submit" @click="register()" class="db w-100 pv3 ph5 bg-brand white f5 fw6 tc bn br2 dim pointer">تسجيل الحساب</button>
      </div>
      <p class="mt4 mb0 tc">لديك حساب. <router-link to="login">تسجيل الدخول</router-link></p>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "RegisterView",
  data() {
    return {
      name: "",
      phone: "",
      email: "",
      password: "",
      success: null,
      msgxd: null,
    };
  },

  methods: {
    register: function () {
      return axios
        .post(`${this.BaseURL}/donations/signup`, {
          name: this.name,
          phone: this.phone,
          email: this.email,
          password: this.password,
        })
        .then((res) => {
          this.msgxd = res.data.msg;
          this.success = res.data.success == true ? true : false;

          this.$store.commit("logIn", {
            loggedIn: this.success,
            user: res.data.user,
          });
          if (this.success) {
            this.$router.push("/");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.alert-error {
  padding: 20px;
  background: #5f1111;
  color: white;
}
.alert-success {
  padding: 20px;
  background: #325a3a;
  color: white;
}
/*! CSS Used from: https://getbootstrap.com/docs/5.0/assets/css/docs.css */
.bd-example > .alert + .alert {
  margin-top: 1rem;
}
/*! CSS Used from: Embedded */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
a {
  background-color: transparent;
}
button,
input {
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
button,
input {
  overflow: visible;
}
button {
  text-transform: none;
}
[type="submit"],
button {
  -webkit-appearance: button;
}
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
[type="submit"]:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText;
}
a,
div,
form,
h1,
input[type="email"],
input[type="password"],
input[type="text"],
p {
  box-sizing: border-box;
}
.ba {
  border-style: solid;
  border-width: 1px;
}
.bn {
  border-style: none;
  border-width: 0;
}
.b--near-white {
  border-color: #f4f4f4;
}
.br2 {
  border-radius: 0.25rem;
}
.bw1 {
  border-width: 0.125rem;
}
.db {
  display: block;
}
.fw6 {
  font-weight: 600;
}
.min-vh-100 {
  min-height: 100vh;
}
.w-100 {
  width: 100%;
}
.black {
  color: #000;
}
.white {
  color: #fff;
}
.bg-white {
  background-color: #fff;
}
.pa3 {
  padding: 1rem;
}
.pt1 {
  padding-top: 0.25rem;
}
.pv3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pv5 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.ph3 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.ph5 {
  padding-left: 4rem;
  padding-right: 4rem;
}
.ma0 {
  margin: 0;
}
.mb0 {
  margin-bottom: 0;
}
.mb3 {
  margin-bottom: 1rem;
}
.mb4 {
  margin-bottom: 2rem;
}
.mt4 {
  margin-top: 2rem;
}
.tc {
  text-align: center;
}
.f3 {
  font-size: 1.5rem;
}
.f5 {
  font-size: 1rem;
}
.center {
  margin-left: auto;
}
.center {
  margin-right: auto;
}
.dim {
  opacity: 1;
}
.dim,
.dim:focus,
.dim:hover {
  transition: opacity 0.15s ease-in;
}
.dim:focus,
.dim:hover {
  opacity: 0.5;
}
.dim:active {
  opacity: 0.8;
  transition: opacity 0.15s ease-out;
}
.pointer:hover {
  cursor: pointer;
}
@media screen and (min-width: 30em) {
  .ph0-ns {
    padding-left: 0;
    padding-right: 0;
  }
}
.bg-brand {
  background-color: #13960d;
}
button,
input {
  font-family: Source Sans Pro, sans-serif;
}
button,
input {
  outline: none;
}
button,
input {
  font-family: inherit;
  text-align: initial;
}
a {
  color: #13960d;
  text-decoration: none;
}
</style>
