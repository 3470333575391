import {createRouter, createWebHistory} from "vue-router";
import store from "@/store";
// middleware and authentication
import middleware from "@grafikri/vue-middleware";
import authentication from "@/middleware/authentication";
// main pages
import HomeView from "@/views/HomeView.vue";
import AboutView from "@/views/AboutView.vue";
import ManagementMembers from "@/views/ManagementMembers.vue";
import ProjectsView from "@/views/ProjectsView.vue";
import ZakahView from "@/views/ZakahView.vue";
import PastProjects from "@/views/PastProjects.vue";
import SingleProject from "@/views/SingleProject.vue";
import ContactView from "@/views/ContactView.vue";
// user
import LoginView from "@/views/user/LoginView.vue";
import RegisterView from "@/views/user/RegisterView.vue";
import ResetPassword from "@/views/user/ResetPassword.vue";
import Dashboard from "@/views/user/Dashboard.vue";

const routes = [
  {path: "/", name: "home", component: HomeView},
  {path: "/projects", name: "projects", component: ProjectsView},
  {path: "/zakah", name: "zakah", component: ZakahView},
  {path: "/past-projects", name: "past-projects", component: PastProjects},
  {path: "/projects/:id", name: "singleProject", component: SingleProject, props: true},
  {path: "/login", name: "login", component: LoginView},
  {path: "/register", name: "register", component: RegisterView},
  {path: "/reset-password", name: "reset", component: ResetPassword},
  {path: "/user", name: "user", component: Dashboard, meta: {middleware: [authentication]}},
  {path: "/about", name: "about", component: AboutView},
  {path: "/m-members", name: "members", component: ManagementMembers},
  {path: "/contact", name: "contact", component: ContactView},
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(middleware({store}));

// router.beforeEach((to, from, next) => {
//   if (to.meta.requireAuth) {
//   } else next();
// });

export default router;

// {
//   path: "/about",
//   component: () => import("../views/AboutView.vue"),
// },
