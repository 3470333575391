<template>
  <div class="ph4-ns ph3 pv6 black" style="background: rgb(242, 244, 246)">
    <div class="center" style="max-width: 1120px">
      <h2 class="mv0 f3 tc"><span>ساهم في الخير</span></h2>
      <div class="relative flex items-center nl4-ns nr4-ns nl2 nr2 mt3">
        <div class="flex-auto">
          <!-- loader -->
          <div class="w-100 tc pt5" v-show="projects.length == 0">
            <img :src="this.loaderGIF" class="h2" />
          </div>
          <!-- end loader -->

          <!-- slider -->
          <p v-show="isEmpty == true" class="tc">لا يوجد مشاريع بعد</p>
          <div v-show="isEmpty == false" class="swiper-wrapper" style="display: block">
            <Splide :options="sliderOptions">
              <SplideSlide v-for="project in projects" :key="project.id">
                <div class="db w-third-ns w-50-m pt3 pb4 ph4">
                  <router-link :to="{name: 'singleProject', params: {id: project.id}}">
                    <div class="bg-white black hover-brand pointer grow style-module--wrapper--2E4lN">
                      <img class="w-100" height="205" :src="project.photo_file" />
                      <div class="absolute absolute--fill bg-black-05 ba b--black-05"></div>
                      <div class="pa3 tr">
                        <h5 class="mv0 f5 fw6 lh-copy truncate-ns">{{ project.title }}</h5>
                        <p class="h1 mb0 mt2 gray f6 truncate-ns lh-solid">{{ project.categories[0].title }}</p>
                        <div class="mt3 flex items-center justify-between">
                          <small>{{ project.expire_date }}</small>
                          <span class="h2"> </span>
                          <div class="mh2"></div>
                          <div class="flex items-center lato br-pill flex-auto flex-grow-0 style-module--wrapper--3NhEB">
                            <span class="ph3-ns ph2 f6 nowrap truncate black">{{ project.fields[1].value }}</span>
                            <!-- <span class="ph3-ns ph2 f6 nowrap truncate black">{{project.fileds != null ? project.fields[1].value : ''}}</span> -->
                            <div class="relative flex items-center brand br-pill style-module--progress--38OK7">
                              <span class="w-100 tc absolute center black f7 fw5">{{ project.progress }}%</span>
                              <svg height="48" viewBox="0 0 36 36">
                                <path :stroke-dasharray="`${project.progress}, 100`" stroke="currentColor" stroke-width="3" stroke-linecap="round" fill="none" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
      </div>
      <div class="mt5 flex justify-center">
        <router-link to="/projects" class="pv3 ph4 bg-brand white f5 fw6 br2 dim">جميع المشاريع</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {Splide, SplideSlide} from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/splide.min.css";

export default {
  components: {
    Splide,
    SplideSlide,
  },
  data() {
    return {
      sliderOptions: {
        perPage: 3,
        autoplay: true,
        rewind: true,
        direction: "rtl",
      },

      projects: [],
      isEmpty: false,
      msg: "",
    };
  },

  methods: {
    getProjects() {
      return this.axios
        .get(`${this.BaseURL}/projects`)
        .then((res) => {
          this.isEmpty = !res.data.topics ? true : false;
          this.projects = res.data.topics;
          this.msg = res.data.msg;
        })
        .catch((e) => {
          this.errors.push(e);
          console.log(e);
        });
    },
    isMobile() {
      if (screen.width <= 480) {
        this.sliderOptions.perPage = 1;
        return true;
      } else if (screen.width > 480 && screen.width <= 960) {
        this.sliderOptions.perPage = 2;
        return true;
      } else {
        return false;
      }
    },
  },

  mounted() {
    this.getProjects();
    this.isMobile();
  },
};
</script>

<style>
.splide__arrow--prev {
  right: 0px !important;
}
.splide__arrow--next {
  left: 0px !important;
}
</style>
