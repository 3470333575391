<template>
  <Splide :options="sliderOptions">
    <SplideSlide v-for="banner in banners" :key="banner.id">
      <div class="style-module--hero--3zWfT relative flex items-center bg-black-80" @click="goToProject(banner.link_url)">
        <div class="style-module--slide--2rb-A absolute absolute--fill cover bg-center" :style="'background-image: url(' + banner.file + ')'"></div>
        <div class="ph4-ns ph3 relative flex-auto z-1">
          <div class="center" style="max-width: 1120px">
            <div class="style-module--wrapper--3HxIv pv0-ns pv4 white">
              <h1 class="ma0 f1-ns f2 fw6 lh-solid">{{ banner.title }}</h1>
              <p class="ma0 mt4 f4-ns f5 lh-copy">{{ banner.details }}</p>
            </div>
          </div>
        </div>
      </div>
    </SplideSlide>
  </Splide>
</template>

<script>
import {Splide, SplideSlide} from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/splide.min.css";

export default {
  name: "Home Slider",
  components: {
    Splide,
    SplideSlide,
  },
  data() {
    return {
      banners: [],

      sliderOptions: {
        autoplay: true,
        pauseOnHover: false,
        pagination: false,
        arrows: false,
        rewind: true,
        direction: "rtl",
        type: "fade",
        speed: 2500,
        interval: 3500,
      },
    };
  },

  methods: {
    getSliders() {
      return this.axios
        .get(`${this.BaseURL}/banners/1`)
        .then((response) => {
          this.banners = response.data.banners;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    goToProject(projectId) {
      if (!isNaN(projectId) && projectId != null) {
        this.$router.push("/projects/" + projectId);
      }
    },
  },
  mounted() {
    this.getSliders();
  },
};
</script>

<style scoped>
@media screen and (max-width: 960px) {
  .f2 {
    font-size: 1.25rem !important;
  }
}
</style>
