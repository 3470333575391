<template>
  <div class="min-vh-100">
    <div class="ph4-ns ph3 pv5">
      <div class="center" style="max-width: 1120px">
        <h1 class="mv0 tc f2-ns f3 lh-solid">{{ user.name }}</h1>
        <p class="mv2 mb0 tc silver">{{ user.email }}</p>
        <div class="center ba b--light-gray br2 mt4 bg-white ph3" style="max-width: 720px">
          <div class="flex items-center justify-center ph3 f5-ns f6 bg-custom-light-gray gray br2 br--top">
            <div class="nl3 nr3">
              <div class="mh3 pa0 bn bg-transparent color-inherit input-reset dim" type="button">
                <span class="dib pv3 bb b--brand brand">تبرعاتي</span>
              </div>
            </div>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th width="30%">المشروع</th>
                <th width="20%">مبلغ</th>
                <th width="25%">التاريخ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="donation in donations" :key="donation.id">
                <td>{{ donation.project_title }}</td>
                <td>{{ donation.donation_amount }} د.ك</td>
                <td>{{ donation.date }}</td>
              </tr>
            </tbody>
          </table>
          <div>
            <p v-if="isEmpty" class="tc">
              لا يوجد اي تبرعات بعد
              <router-link :to="{name: 'projects'}" class="ph2 b--brand br2 f5-ns f7">تبرع الان</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: this.$store.state.user,
      donations: null,
      isEmpty: false,
    };
  },

  methods: {
    getMyDonations() {
      return this.axios
        .get(`${this.BaseURL}/donations/getDonations/${this.user.access_token}`, {
          params: {
            access_token: this.user.access_token,
          },
        })
        .then((res) => {
          this.donations = res.data.donations;
          this.isEmpty = !this.donations ? true : false;
        })
        .catch((e) => {
          console.log("erorrr");
          console.log(e);
        });
    },
  },
  mounted() {
    this.getMyDonations();
  },
};
</script>

<style scoped>
@media screen and (max-width: 960px) {
  .ph3 {
    padding-left: 0.1rem;
    padding-right: 0.1rem;
  }
  tr {
    font-size: smaller;
  }
  .table td,
  .table th {
    padding: 0.35rem !important;
  }
}
table {
  border-collapse: collapse;
}
th {
  text-align: inherit;
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
</style>
